import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Crm from '@/Models/Crm'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import router from '@/router'
import * as Helpers from '@/helpers'
import menuModule from '@/store/menu'
import Item from '@/Models/Item'
import Paginator from '@/components/Paginator/Paginator.vue'
import Animation from '@/components/Animation/index.vue'
@Component({
  components: {
    ModalDelete,
    Paginator,
    ValidationObserver,
    ValidationProvider,
    Animation,
  },
})
export default class ViewReportIdentification extends Vue {
  showList = false
  data: Array<Crm> = []
  objCrm: Crm = new Crm()
  //accion que envia dependiendo la opcion
  action = 'add'
  params: Array<Item> = []
  loading = false
  delete_modal_active = false
  range_date = ''
  currentPage = 1
  //th de la tabla
  header: Array<any> = [
    {
      field: 'member_number',
      label: '# miembro',
    },
    {
      field: 'client_name',
      label: 'Cliente',
    },
    {
      field: 'migratory_status',
      label: 'Estado',
    },
    {
      field: 'client_email',
      label: 'Email',
    },
    {
      field: 'date_expire_membership',
      label: 'Vencimiento membresia',
    },
    {
      field: 'phone',
      label: 'Teléfono',
    },
  ]

  async getSearch() {
    this.data = []
    try {
      const res = await http.post(
        `api/crm/crm_client_file/reportIndentification/`,
        { range_date: this.range_date },
      )
      this.data = res.data
      /*  this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      }) */
      setTimeout(() => {
        this.loading = false
      }, 1000)
    } catch (error: any) {
      this.loading = false
      this.$buefy.toast.open({
        message: error.response.data,
        type: 'is-danger',
      })
    }
  }
}
